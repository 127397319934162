<template src="./index.html" />

<script>
import {
  BBreadcrumb,
  BButton,
  BCard,
  BRow,
  BCol, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButtonGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import DatePicker from 'vue2-datepicker'
// eslint-disable-next-line import/extensions
import 'vue2-datepicker/locale/vi.js'
import 'vue2-datepicker/index.css'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import FormCourse from '@/views/admin/category/course/FormCourse.vue'

import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import CourseSemester from '@/views/admin/category/course-semester/CourseSemester.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'

export default {
  name: 'Course',
  components: {
    CourseSemester,
    BBreadcrumb,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    VueGoodTable,
    vSelect,
    FormCourse,
    BButtonGroup,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: true,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      items: [],
      itemsDisplay: [],
      editMode: false,
      fields: [
        {
          field: 'code',
          label: 'Mã',
          sortable: true,
          thClass: 'text-center',
        },
        {
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Tên khóa học',
            trigger: 'enter',
          },
          label: 'Tên khóa học',
          sortable: true,
          thClass: 'text-center',
        },
        {
          field: 'startDate',
          label: 'Bắt đầu',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'dd/mm/yyyy',
        },
        {
          field: 'endDate',
          label: 'Kết thúc',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'dd/mm/yyyy',
        },
        {
          field: 'trainingSystemName',
          filterOptions: {
            enabled: true,
            placeholder: 'Tất cả',
            trigger: 'enter',
            filterDropdownItems: [],
          },
          label: 'Hệ đào tạo',
          sortable: true,
          thClass: 'text-center',
        },
        {
          field: 'status',
          label: 'Trạng thái',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          field: 'action',
          label: 'Thao tác',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
      ],
      row_selected: null,
      dataCourse: {},

    }
  },
  computed: {
    ...mapGetters({
      courses: 'course/courses',
      total: 'course/total',
      trainingSystems: 'dropdown/trainingSystems',
      statuses: 'course/statuses',
    }),
    rows() {
      return this.items.length
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.COURSE)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.COURSE)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.COURSE)
    },
  },
  async created() {
    // init data FROM API
    await this.getTrainingSystems({ organizationId: getUser().orgId })
    const trainingSystemsCV = this.trainingSystems.map(item => ({ value: item.value, text: item.label }))
    this.$set(this.fields[4].filterOptions, 'filterDropdownItems', trainingSystemsCV)
    await this.getData(this.serverParams.page, this.serverParams.perPage)
  },
  methods: {
    ...mapActions({
      getCourses: 'course/getCourses',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      createCourse: 'course/createCourse',
      updateCourse: 'course/updateCourse',
      deleteCourse: 'course/deleteCourse',
    }),
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    // load items is what brings back the rows from server
    loadItems() {
      this.btnSearchClick()
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    async editItem(item) {
      this.row_selected = item
      await this.$refs.DetaiTitlePopupRef.showModal(item)
    },

    async deleteItem(item) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa dữ liệu?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          this.loading = true
          try {
            const response = await this.deleteCourse(item.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getData(this.serverParams.page, this.serverParams.perPage)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.loading = false
          }
        }
      })
    },

    async btnSearchClick() {
      this.loading = true
      await this.getData(this.serverParams.page, this.serverParams.perPage)
    },

    openModal() {
      this.dataCourse = {}
      this.$refs['modal-course'].show()
    },

    editCourse(data) {
      this.dataCourse = data
      this.$refs['modal-course'].show()
    },

    editSemester(data) {
      this.dataCourse = data
      this.$refs['modal-semesters'].show()
    },

    async saveDataCourse(data) {
      const dataSend = {
        id: data.id,
        name: data.name,
        code: data.code,
        orderNo: data.orderNo,
        status: data.status,
        startDate: data.startDate,
        endDate: data.endDate,
        trainingSystemId: data.trainingSystemId,
        scoringFormulaId: data.scoringFormulaId,
      }
      this.loading = true
      try {
        const result = dataSend.id ? await this.updateCourse(dataSend) : await this.createCourse(dataSend)
        if (result) {
          const { isSuccessful, message } = result
          if (isSuccessful) {
            this.showToast(message, 'CheckIcon', 'success', '')
            this.$refs['modal-course'].hide()
            await this.getData(this.serverParams.page, this.serverParams.perPage)
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.loading = false
      }
    },

    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },

    async getData(page, perPage) {
      this.items = []
      this.itemsDisplay = []
      let sort = ''
      // const temp = this.serverParams.sort[0]
      // if (temp.field === 'name' && temp.type === 'asc') {
      //   sort = '1_asc'
      // } else if (temp.field === 'name' && temp.type === 'desc') {
      //   sort = '1_desc'
      // }
      const { field, type } = this.serverParams.sort[0]
      const selectedColumn = this.fields.findIndex(e => e.field === field)
      sort = `${selectedColumn + 1}_${type}`
      await this.getCourses({
        currentPage: page,
        itemsPerPage: perPage,
        organizationId: getUser().orgId,
        trainingSystemId: this.serverParams.columnFilters.trainingSystemName ? this.serverParams.columnFilters.trainingSystemName : null,
        searchTerm: this.serverParams.columnFilters.name ? this.serverParams.columnFilters.name : '',
        status: '',
        sort,
      })
      this.items = this.courses
      this.totalRecords = this.total
      this.itemsDisplay = [...this.items]
    },
  },
}
</script>
<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
